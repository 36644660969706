.mission{
	position: relative;
	.planet{
		position: absolute;

		left: 0%;
		top: 0%;
		transform: translate(30%, -65%);

		width: 50vw;

		z-index: -1;
	}
	.rocket{
		position: absolute;

		right: 0%;
		top: 0%;
		transform: translate(0%, 0%);

		width: 40vw;

		z-index: -1;
	}
	.cloud{
		position: absolute;

		right: 0%;
		top: 0%;
		transform: translate(30%, 40%);

		width: 40vw;

		z-index: -1;
	}
	.cloud-2{
		position: absolute;

		left: 0%;
		top: 0%;
		transform: translate(-35%, 75%);

		width: 35vw;

		z-index: -1;
	}
	.planet-flag{
		position: absolute;

		left: 0%;
		top: 0%;
		transform: translate(0%, 60%);

		width: 50vw;

		z-index: -1;
	}
	.cloud-bottom{
		position: absolute;

		right: 0%;
		bottom: 0%;
		transform: translate(50%, 30%);

		width: 50vw;

		z-index: -1;
	}
	.title{
		font-size: 8vmin;
		line-height: 2em;
	}
	.text{
		font-size: 2.5vmin;
	}
	.empty-block{
		display: block;
		width: 100vw;
		height: 60vh;
	}
	.empty-block-half{
		display: block;
		width: 100vw;
		height: 15vh;
	}
}