.card-drag-hidden {
	display: block;

	transition: 0.3s;
	position: absolute;
	transform: scale(0) translateX(-80%) translateY(-80%) !important;
	opacity: 0 !important;
	display: flex;
	flex-direction: column;
	align-items: center !important;
	justify-content: center;
	height: 100%;
	z-index: -1;

	.text-paragraph {
		height: 30px;
	}
}

.card-drag-title {
	width: 80% !important;
	font-size: 2.5vmin;
	@media (max-width: 768px) {
		font-size: 16px;
	}
}

.card-rouded {
	border-radius: 47px !important;
}

.card-drag-lg {
	width: 20%;
	height: 60vh;
	max-width: 671px;
	background-color: rgba(255, 255, 255, 0.9) !important;
	@media (max-width: 1024px) {
		width: 40%;
	}
}
.card-drag-lg-content {
	padding:10px;
	display: block;

	transition: 0.3s;
	position: absolute;
	transform: scale(1);
	opacity: 1;

	display: flex;
	flex-direction: column;
	align-items: center !important;
	justify-content: center;
	height: 100%;
	.card-drag-title {
		width: 100%;
		text-align: center;
		margin-right: 10px !important;
		@media (max-width: 768px) {
			margin-right: 0px !important;
		}
	}
	svg{
		width: 40%;
		height: 40%;
	}
	@media (max-width: 768px) {
		svg {
			width: 80%;
			height: 80%;
		}
	}
}

.card-drag-sm {
	width: 20%;
	height: 30vh;
	max-width: 671px;
	background-color: rgba(255, 255, 255, 0.9) !important;
	@media (max-width: 1024px) {
		width: 40%;
	}
}
.card-drag-sm-content {
	padding:10px;
	display: block;

	transition: 0.3s;
	position: absolute;
	transform: scale(1);
	opacity: 1;

	display: flex;
	flex-direction: column;
	align-items: center !important;
	justify-content: center;
	height: 100%;
	.card-drag-title {
		width: 90%;
		text-align: center !important;
	}
	svg{
		width: 40%;
		height: 40%;
	}
	@media (max-width: 768px) {
		svg {
			margin-left: 12px;
		}
	}
}

.card-mobile {
	float: left;
	position: relative;
	width: 100vw;
	height: calc(88vh - 56px);
	.card-mobile-content {
		width: 90%;
		height: 88%;
		border-radius: 25px;
	}
	.card-mobile-content-form {
		margin-bottom: 100px;
		border-radius: 25px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	input {
		font-size: 12px;
	}
}

.card-drag-full {
	max-width: 1378px;
	max-height: 695px;
	width: 70vw;
	height: 60vh;
	background-color: rgba(255, 255, 255, 0.9) !important;
	visibility: visible;
	opacity: 1;

	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%, 0%);
	
	margin-bottom: 5em;
	margin-top: 5em;

	cursor: pointer;
}
.card-drag-full-content {
	display: block;

	transition: 0.3s;
	position: absolute;
	transform: scale(1);
	opacity: 1;

	display: flex;
	flex-direction: column;
	align-items: center !important;
	justify-content: center;
	height: 100%;
	width: 100%;
	.mini-header{
		font-size: 3vmin !important;
		margin-bottom: 0.5em;
	}
	.text-paragraph{
		font-size: 2vmin;
	}
	.btn{
		font-size: 2vmin;
	}
}

.card-drag-full-hide {
	visibility: hidden;
	opacity: 0;
	height: 0px !important;
}
