@font-face {
	font-family: "Open Sans Bold";
	src: local("Open Sans Bold"),
		url("../fonts/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "Open Sans Light";
	src: local("Open Sans Light"),
		url("../fonts/OpenSans-Light.ttf") format("truetype");
}
@font-face {
	font-family: "Open Sans Regular";
	src: local("Open Sans Regular"),
		url("../fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "Open Sans Semibold";
	src: local("Open Sans Semibold"),
		url("../fonts/OpenSans-Semibold.ttf") format("truetype");
}

@font-face {
	font-family: "Anodina-Bold";
	src: local("Anodina-Bold"),
		url("../fonts/Anodina-Bold.otf") format("truetype");
}
@font-face {
	font-family: "Anodina-BoldItalic";
	src: local("Anodina-BoldItalic"),
		url("../fonts/Anodina-BoldItalic.otf") format("truetype");
}
@font-face {
	font-family: "Anodina-ExtraBold";
	src: local("Anodina-ExtraBold"),
		url("../fonts/Anodina-ExtraBold.otf") format("truetype");
}
@font-face {
	font-family: "Anodina-ExtraBoldItalic";
	src: local("Anodina-ExtraBoldItalic"),
		url("../fonts/Anodina-ExtraBoldItalic.otf") format("truetype");
}
@font-face {
	font-family: "Anodina-ExtraLight";
	src: local("Anodina-ExtraLight"),
		url("../fonts/Anodina-ExtraLight.otf") format("truetype");
}
@font-face {
	font-family: "Anodina-ExtraLightItalic";
	src: local("Anodina-ExtraLightItalic"),
		url("../fonts/Anodina-ExtraLightItalic.otf") format("truetype");
}
@font-face {
	font-family: "Anodina-Light";
	src: local("Anodina-Light"),
		url("../fonts/Anodina-Light.otf") format("truetype");
}
@font-face {
	font-family: "Anodina-LightItalic";
	src: local("Anodina-LightItalic"),
		url("../fonts/Anodina-LightItalic.otf") format("truetype");
}
@font-face {
	font-family: "Anodina-Regular";
	src: local("Anodina-Regular"),
		url("../fonts/Anodina-Regular.otf") format("truetype");
}
@font-face {
	font-family: "Anodina-RegularItalic";
	src: local("Anodina-RegularItalic"),
		url("../fonts/Anodina-RegularItalic.otf") format("truetype");
}
