.menu {
	z-index: 10;
	.menu-bars {
		max-height: 25px;
		max-width: 52px;
		width: 2rem;
		position: relative;
		transition: 0.3s;
		cursor: pointer;
		&:hover {
			opacity: 0.5;
		}
	}
	.drawer {
		width: 250px;
		transition: 0.3s;
		right: 10px;
		z-index: 10;
		opacity: 1;
		padding-left: 25px;
		padding-right: 25px;
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 47px;
		font-size: 2vmin;
	}
	.drawer-hidden {
		transition: 0.3s;
		opacity: 0;
		visibility: hidden;
		z-index: -10;
	}
}

.pointer {
	cursor: pointer !important;
	position: relative;
}

.icon-header {
	width: 8vmin;
	height: 8vmin;
}

.tip {
	display:inline-block;
	position:relative;
}
.tip:hover { text-decoration:none }
.tip b {
	bottom:95%;
	margin:auto;
	position:absolute;
	display:block;
	z-index:98;
	text-align:center;
	left:-1000px;
	right:-1000px;
}
.tip:hover b:after {
	background-color: $light;
	color: $primary;
	border-radius: 20px;
	content:attr(label);
	display:table;
	margin:auto;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 3px;
	padding-bottom: 3px;
	white-space:nowrap;
	z-index:98;
	text-align:center;
	font-size: 2vmin;
}
