.logo {
	max-height: 110px;
	max-width: 160px;
	width: 16vmin;
}

.footer {
	.logo {
		max-height: 95px;
		max-height: 138px;
		width: 16vmin;
	}
	.sponsor {
		max-width: 134px;
		max-height: 46px;
		width: 16vmin;
	}
	.footer-mini-text {
		font-size: 2vmin;
	}
	.footer-head {
		font-size: 2.5vmin;
		font-weight: bold;
	}
	.footer-social {
		max-height: 22px;
		max-width: 25px;
		width: 16vmin;
	}

	.twitter {
		display: block;
		height: 20px;
		transition: 0.3s;
		fill: $white;
		cursor: pointer;
	}
	.twitter:hover {
		fill: $twitter;
	}

	.facebook {
		@extend .twitter;
	}
	.facebook:hover {
		fill: $facebook;
	}

	.insta {
		@extend .twitter;
	}
	.insta:hover {
		fill: $insta;
	}

	.linkedin {
		@extend .twitter;
	}
	.linkedin:hover {
		fill: $linkedin;
	}
}
