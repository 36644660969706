@mixin contactus-bubble($color, $file) {
	z-index: 999;
	position: fixed;
	right: 30px;
	bottom: 20px;
	border-radius: 4vmin;

	box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);

	padding-left: 2vmin;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	background-color: $color;

	cursor: pointer;

	transition: 1.3s;

	color: $light;
	font-size: 1.8vmin;

	opacity: 0.9;

	&:hover {
		opacity: 1;
	}

	.text-container {
		width: 75%;
		padding: 10px;
		font-size: 2vmin;
	}
	.icon-container {
		width: 25%;
		padding: 10px;
		padding-right: 4vmin;
		border-top-right-radius: 4vmin;
		border-bottom-right-radius: 4vmin;
		$url: "../images/#{$file}.svg";
		background: url($url) no-repeat scroll 10px 10px;
		background-size: 4vmin 4vmin;
		background-color: darken($color, 10);

		cursor: default;
		transition: 1.3s;
	}
	.icon-placeholder {
		width: 4vmin;
		height: 4vmin;
		cursor: pointer;
	}

	&:hover {
		background-color: darken($color, 10);
	}

	.popup {
		position: absolute;

		right: 0;
		bottom: 0px;
		padding: 10px;
		border-radius: 4vmin;
		border-bottom-left-radius: 0px;
		z-index: 1;

		background-color: darken($color, 10);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;

		cursor: default;
		opacity: 1;
		visibility: visible;

		transition: 0.3s;
	}
	.popup-hidden {
		z-index: -1;
		opacity: 0;
		visibility: hidden;
	}
	.popup-row{
		position: absolute;

		right: 0;
		bottom: 0px;
		padding-left: 2vmin;
		padding-right: 2vmin;
		padding: 10px;
		border-radius: 4vmin;
		z-index: 1;

		background-color: darken($color, 10);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;

		cursor: default;
		opacity: 1;
		visibility: visible;

		transition: 0.3s;
	}
}

.whatsapp-button {
	@include contactus-bubble($whatsapp, whatsapp);
}

.messenger-button {
	@include contactus-bubble($messenger, messenger);
}

.slack-button {
	@include contactus-bubble($slack, slack);
}

.skype-button {
	@include contactus-bubble($skype, skype);
}

.telegram-button {
	@include contactus-bubble($telegram, telegram);
}

@mixin contactus-card($color, $file) {
	position: fixed;
	bottom: -40%;
	right: 20px;
	width: 50vmin;
	z-index: 1;

	transition: 0.3s;

	box-shadow: 0px 17px 10px -5px rgba(0, 0, 0, 0.2);

	border-radius: 2vmin;

	.contact-head {
		border: none;
		padding: 2vmin;
		border-top-left-radius: 2vmin;
		border-top-right-radius: 2vmin;
		transition: 1.3s;
		font-size: 2vmin;
		background: $color;
		position: relative;

		i {
			z-index: 1;
		}

		.background {
			position: absolute;
			width: 80%;
			height: 70%;
			z-index: 0;
			top: 0;
			left: 10%;
		}
	}
	.image-container {
		.image-placeholder {
			width: 6vmin;
			height: 6vmin;
			$url: "../images/#{$file}.svg";
			background: url($url) no-repeat scroll 0px 0px;
			background-size: 6vmin 6vmin;
		}
		box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.2);
		border-radius: 50%;
		padding: 2vmin;
		background-color: darken($color, 20);

		transition: 1.3s;
		z-index: 1;
	}
	.logo-container {
		img {
			width: 6vmin;
			height: 6vmin;
		}
		box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.2);
		border-radius: 50%;
		padding: 2vmin;
		background-color: darken($primary, 20);
	}
	.contact-body {
		background-color: white;
		border-bottom-left-radius: 2vmin;
		border-bottom-right-radius: 2vmin;
		.contact {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			padding: 2vmin;
			transition: 0.3s;
			border-bottom-left-radius: 2vmin;
			border-bottom-right-radius: 2vmin;
			cursor: pointer;
			font-size: 2vmin;
			&:hover {
				background-color: darken($light, 5);
			}
		}
		.mini-text {
			font-size: 1.5vmin;
		}
	}
}

.whatsapp-card {
	@include contactus-card($whatsapp, whatsapp);
}

.messenger-card {
	@include contactus-card($messenger, messenger);
}

.slack-card {
	@include contactus-card($slack, slack);
}

.skype-card {
	@include contactus-card($skype, skype);
}

.telegram-card {
	@include contactus-card($telegram, telegram);
}

.card-visible {
	bottom: 12vmin !important;
}
