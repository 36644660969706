.what-we-do{
	position: relative;
	height: 80%;
	.title{
		font-size: 8vmin;
		line-height: 2em;
	}
	.text{
		font-size: 2.5vmin;
	}
	svg{
		width: 50vw;
		height: 50vw;
		float: right;
	}
	.show{
		opacity: 1;
		visibility: visible;
		transition: 0.3s;
	}
	.hide{
		opacity: 0;
		visibility: hidden;
		transition: 0.3s;
	}
	.slide-container{
		position: relative;
		width:50%;
		.slide{
			display: flex;
			flex-direction:column;
			align-items:flex-start;
			justify-content:center;
		}
	}
}