$primary: #44adfd;
$main-gradient: #96dbfd, #44adfd;
$main-gradient-reversed: #44adfd, #96dbfd;
$button-gradient: #9373f4, #6864f5;
$whatsapp:#0EC151;
$messenger: #0078FF;
$slack: #49154B;
$skype: #00AFF0;
$telegram: #0088CC;

$twitter: #3BA9EE;
$facebook: #3b5998;
$insta: #e95950;
$linkedin: #0e76a8;

$input-background: #F8F5FF;
$light: #FFFFFF;
$shady-text: #6A6A6A;

$gradients-landing: (
	"main": $main-gradient,
	"button": $button-gradient
);

$theme-colors: (
	shadytext:$shady-text
);

$enable-rounded: true !default;
$border-radius: 24px;