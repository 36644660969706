.grid {
	position: relative;
	width: 100% !important;
	margin-left: calc(100vw/12) !important;
	margin-right: calc(100vw/12) !important;
	visibility: visible;
	opacity: 1;
	display: block;
	transition: visibility 0.3s, opacity 0.3s, display 0.4s;
}
.item {
	position: absolute;
	cursor: pointer;
	z-index: 1;
}
.item.muuri-item-dragging {
	z-index: 3;
}
.item.muuri-item-releasing {
	z-index: 2;
}
.item.muuri-item-hidden {
	z-index: 0;
}
.item-content {
	position: relative;
}


.grid-hide{
	opacity: 0;
	visibility: hidden;
	height: 0px !important;
	.item{
		display: none !important;
	}
}