.portfolio {
	.cover-container {
		position: relative;
		height: 80%;
		.title {
			font-size: 8vmin;
			line-height: 2em;
		}
		.text {
			font-size: 2.5vmin;
		}
		.cover {
			width: 50vw;
			height: 50vw;
			float: right;
			margin-top: -15%;
		}
		.show {
			opacity: 1;
			visibility: visible;
			transition: 0.3s;
		}
		.hide {
			opacity: 0;
			visibility: hidden;
			transition: 0.3s;
		}
		.paragraph-container {
			position: relative;
			width: 50%;
			.paragraph {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}
		}
	}
	.slides {
		animation: looper 20s infinite;
		animation-timing-function: ease-in-out;
		.slide{
			min-width: 40vw;
			max-width: 50vw;
			img{
				width: 100%;
				height: 100%;
			}
		}

		@keyframes looper {
			0% {
				transform: translateX(0);
			}
			8% {
				transform: translateX(0);
			}
			16% {
				transform: translateX(-30%);
			}
			24% {
				transform: translateX(-30%);
			}
			32% {
				transform: translateX(-60%);
			}
			40% {
				transform: translateX(-60%);
			}
			48% {
				transform: translateX(-90%);
			}
			56% {
				transform: translateX(-90%);
			}
			64% {
				transform: translateX(-120%);
			}
			72% {
				transform: translateX(-120%);
			}
			80% {
				transform: translateX(-160%);
			}
			90% {
				transform: translateX(-160%);
			}
			100% {
				transform: translateX(0%);
			}
		}
	}
}
