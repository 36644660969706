body{
	font-family: Anodina-Regular;
}

@each $color, $value in $gradients-landing {
	.gradient-#{$color} {
		@include gradient-directional(nth($value, 1), nth($value, 2), 45deg);
	}
}

.custom-control-label{
	overflow-wrap: break-word !important;
	word-wrap: break-word !important;
}
.custom-control-label::before {
	border-color: $primary;
	@media (max-width: 768px) {
		width: 10px;
		height: 10px;
	}
}
.custom-control-label::after {
	border-color: $primary;
	@media (max-width: 768px) {
		width: 10px;
		height: 10px;
	}
}

.nounderline {
	text-decoration: none !important;
}

.btn-rounded {
	transition: 0.3s !important;
	border-radius: 25px;
	border-width: 0;
	@media (max-width: 768px) {
		font-size: 14px;
	}
	transition:0.3s;
}
.btn-rounded:hover {
	@include gradient-directional(
		darken(nth($button-gradient, 1), 10%),
		darken(nth($button-gradient, 2), 10%),
		45deg
	);
}
.btn-grad{
	@include gradient-directional(
		nth($button-gradient, 1),
		nth($button-gradient, 2),
		45deg
	);
	transition:0.3s;
}
.btn-grad:hover{
	@include gradient-directional(
		darken(nth($button-gradient, 1), 10%),
		darken(nth($button-gradient, 2), 10%),
		45deg
	);
}

.btn-xsm {
	font-size: 12px;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 3px;
	padding-bottom: 3px;
}

.contact-card {
	border-radius: 47px;
}

.pointer {
	cursor: pointer;
	transition: 0.3s;
}
.pointer:hover {
	color: $white;
}

.border-thick {
	border-width: 3px;
}

.mini-text {
	font-size: 12px;
}
.mini-link {
	font-size: 16px;
	@media (max-width: 768px) {
		font-size: 14px;
	}
}
.mini-header {
	font-size: 18px !important;
	@media (max-width: 768px) {
		margin-top: 25px;
	}
}
.footer-tile {
	margin-top: auto !important;
}
.radio-points {
	display: block;
	height: 200px;
	margin-top: 20px !important;
	margin-bottom: 20px !important;
	width: 100% !important;
	@media (max-width: 768px) {
		height: 65vw;
	}
}
.custom-radio {
	@media (max-width: 768px) {
		padding-right: 0px !important;
		width: 60%;
		input {
			font-size: 10px !important;
		}
		label {
			font-size: 10px !important;
			margin-right: 10px;
		}
	}
}
.text-paragraph {
	font-size: 16px;
	@media (max-width: 768px) {
		height: 50vh !important;
	}
}

.modal-header,
.modal-footer {
	border: 0;
}
.modal-content {
	text-align: left;
	letter-spacing: 0;
	font-family: "Open Sans Light";
	font-size: 14px;
	color: $primary;
	border-color: transparent;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 35px;
	padding-bottom: 35px;
	border-radius: 47px;
	@media (max-width: 768px) {
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 25px;
	}
}
.modal-title {
	font-family: "Open Sans Bold";
	letter-spacing: 0;
	font-size: 18px;
	color: $primary;
	opacity: 1;
}

.form-control {
	@extend .form-control;
	border-width: 0;
	box-shadow: none;
	background-color: $input-background;
	border-radius: 45px;
	color: $primary;
}
.form-control:focus {
	color: $dark;
	background-color: $light;
}

.react-select__control {
	background-color: $input-background !important;
	border-width: 0px !important;
	width: 100% !important;
	border-radius: 45px !important;
	@media (max-width: 768px) {
		width: 35vw !important;
		min-height: 28px !important;
		.react-select__indicator,
		.react-select__dropdown-indicator {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}
}
.react-select__value-container {
	background-color: transparent !important;
	border-width: 0px !important;
	div {
		color: $primary !important;
		@media (max-width: 768px) {
			font-size: 12px !important;
		}
	}
}

.hide-splash {
	display: none;
}
.hide-app {
	display: none;
}
.show-app {
	display: inline;
}
.splash-screen {
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	@include gradient-directional(
		nth($main-gradient-reversed, 1),
		nth($main-gradient-reversed, 2),
		45deg
	);
	.s1 {
		position: absolute;
		transform: scale(1) translateX(-80%) translateY(-50%);
		width: 100%;
		animation-name: splash1;
		animation-duration: 30000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-play-state: initial;
	}
	.s2 {
		position: absolute;
		transform: scale(1) translateX(30%) translateY(20%);
		width: 100%;
		animation-name: splash2;
		animation-duration: 50000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-play-state: initial;
	}
	.s3 {
		position: absolute;
		transform: scale(1) translateX(-40%) translateY(80%);
		width: 100%;
		animation-name: splash3;
		animation-duration: 12000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-play-state: initial;
	}

	@media (min-width: 769px) {
		display: none;
		visibility: hidden;
		opacity: 0;
	}
}

@keyframes splash1 {
	0% {
		transform: scale(1) translateX(-80%) translateY(-50%);
	}
	100% {
		transform: scale(1) translateX(20%) translateY(200%);
	}
}

@keyframes splash2 {
	0% {
		transform: scale(1) translateX(-80%) translateY(20%);
	}
	100% {
		transform: scale(1) translateX(-20%) translateY(-50%);
	}
}

@keyframes splash3 {
	0% {
		transform: scale(1) translateX(-80%) translateY(80%);
	}
	100% {
		transform: scale(1) translateX(0%) translateY(-40%);
	}
}

.center-logo {
	top: 0; left: 0; right: 0; bottom: 0;
	margin: auto;
}

.social-media {
	position: relative;
	a {
		width: 50%;
		img: {
			max-width: 250px;
		}
	}
}

.tabber {
	background-color: $light;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	padding-top: 20px;
	padding-bottom: 30px;
}
.tabber-collapse {
	transition: 0.3s;
	height: 10%;
}
.tabber-expand {
	transition: 0.3s;
	height: 90%;
}
.search-input {
	background-color: $input-background;
	box-shadow: none;
	border-width: 0;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 20px;
	padding-left: 20px;
	border-radius: 20px;
}

.hidden-check {
	position: absolute;
	top: 25%;
	left: 8%;
	opacity: 0;
}


.btn{
	font-size: 2.2vmin;
}