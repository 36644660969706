.steps {
	float: left;
	position: relative;
	width: 100vw;
	top: 0;
	left: 0;

	@include gradient-directional(
		nth($main-gradient-reversed, 1),
		nth($main-gradient-reversed, 2),
		45deg
	);
	z-index: 100;
	overflow: hidden;

	.cover {
		position: absolute;
		right: 0%;
		top: 0%;
		width: 100vw;
		height: 45vh;
		transform: translate(0%, 15vh);
	}
	.cloud-up {
		position: absolute;
		left: 0%;
		top: 0%;
		transform: translate(-45%, -45%);
		width: 55vw;
		height: 55vw;
	}
	.cloud-right {
		position: absolute;
		right: 0%;
		bottom: 0%;
		transform: translate(55%, 30%);
		width: 55vw;
		height: 55vw;
	}
	.cloud-left {
		position: absolute;
		left: 0%;
		bottom: 0%;
		transform: translate(-35vw, 0vh);
		width: 55vw;
		height: 55vh;
	}
	.dots {
		.dot-filled {
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: white;
		}
		.dot-empty {
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			border-color: white;
			border-width: 1px;
			border-style: solid;
		}
	}

	.s1 {
		position: absolute;
		transform: scale(1) translateX(-80%) translateY(-50%);
		width: 100%;
		animation-name: splash1;
		animation-duration: 30000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-play-state: initial;
	}
	.s2 {
		position: absolute;
		transform: scale(1) translateX(30%) translateY(20%);
		width: 100%;
		animation-name: splash2;
		animation-duration: 50000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-play-state: initial;
	}
	.s3 {
		position: absolute;
		transform: scale(1) translateX(-40%) translateY(80%);
		width: 100%;
		animation-name: splash3;
		animation-duration: 12000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-play-state: initial;
	}
}
.swipe {
	overflow: hidden;
	visibility: hidden;
	position: relative;
}
.swipe-wrap {
	overflow: hidden;
	position: relative;
}
