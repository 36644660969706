.about{
	position: relative;
	.cloud-top {
		position: absolute;

		right: 0%;
		top: 0%;
		transform: translate(21vw, -26vw);
		
		width: 90vw;
		height: 75vw;


		z-index: -1;

		overflow: hidden;
	}
	.rocket{
		position: absolute;

		right: 0%;
		top: 0%;
		transform: translate(-50vw, 12vw);
		
		width: 25vw;
		height: 15vw;


		z-index: -1;
	}
	.smoke{
		position: absolute;
		width: 100vw;

		left: 0%;
		top: 0%;
		transform: translate(-20vw, 27vw);
		
		width: 70vw;
		height: 70vw;

		z-index: -1;
	}
	.space-man{
		position: absolute;
		width: 100vw;

		left: 0%;
		top: 0%;
		
		transform: translate(8vw, 38vw);
		
		width: 50vw;
		height: 60vw;

		z-index: -1;
	}
	.cloud-bottom{
		position: absolute;

		right: 0%;
		bottom: 0%;
		transform: translate(22vw, 15vw);
		
		width: 50vw;
		height: 50vw;

		z-index: -1;
	}
	.title{
		font-size: 8vmin;
		line-height: 1.5em;
	}
	.text{
		font-size: 2.5vmin;
	}
	.empty-block{
		display: block;
		width: 100vw;
		height: 35vw;
	}
	.empty-block-half{
		display: block;
		width: 100vw;
		height: 10vw;
	}
}