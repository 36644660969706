.VRIcon {
	@media (max-width: 768px) {
		#glass{
			animation-name: shine;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 43% 39%;
		}
		#glass-frame {
			animation-name: shine-fade;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 43% 39%;
		}
		#glass-dots {
			animation-name: move-around;
			animation-duration: 2000ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
		}
	}
}
.VRIcon:hover #glass {
	animation-name: shine;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 43% 39%;
}
.VRIcon:hover #glass-frame {
	animation-name: shine-fade;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 43% 39%;
}
.VRIcon:hover #glass-dots {
	animation-name: move-around;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
}


.DigitalMarket {
	@media (max-width: 768px) {
		#microphone{
			animation-name: lean-back;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: bottom right 60px;
		}
		#voice {
			animation-name: move-around-voice;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: bottom right 60px;
		}
		#video {
			animation-name: tada;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: center;
		}
		#play{
			animation-name: flip;
			animation-duration: 2000ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 50% 20%;
		}
	}
}
.DigitalMarket:hover #microphone{
	animation-name: lean-back;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: bottom right 60px;
}
.DigitalMarket:hover #voice{
	animation-name: move-around-voice;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: bottom right 60px;
}


.Production {
	@media (max-width: 768px) {
		#video{
			animation-name: tada;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: center;
		}
		#play{
			animation-name: flip;
			animation-duration: 2000ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 50% 20%;
		}
	}
}
.Production:hover #video{
	animation-name: tada;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: center;
}
.Production:hover #play{
	animation-name: flip;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 50% 20%;
}


.SEO {
	@media (max-width: 768px) {
		#data{
			animation-name: jello;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 50% 20%;
		}
	}
}
.SEO:hover #data{
	animation-name: jello;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 50% 20%;
}


.WebDev{
	@media (max-width: 768px) {
		#closing-tag{
			animation-name: separate-close-tag;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: center;
		}
		#open-tag{
			animation-name: separate-open-tag;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: center;
		}
		#separator-tag{
			animation-name: shine-tag;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#email-icon{
			animation-name: rotate-grow-email;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
	}
}
.WebDev:hover #closing-tag{
	animation-name: separate-close-tag;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: center;
}
.WebDev:hover #open-tag{
	animation-name: separate-open-tag;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: center;
}
.WebDev:hover #separator-tag{
	animation-name: shine-tag;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}


.EmailIcon{
	@media (max-width: 768px) {
		#email-icon{
			animation-name: rotate-grow-email;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#rect-UI-top{
			animation-name: ui-top;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#rect-UI-middle{
			animation-name: ui-middle;
			animation-duration: 1000ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#rect-UI-bottom{
			animation-name: ui-bottom;
			animation-duration: 1800ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#ci-UI-1{
			animation-name: ui-ci-1;
			animation-duration: 2000ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#ci-UI-2{
			animation-name: ui-ci-2;
			animation-duration: 2200ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#ci-UI-3{
			animation-name: ui-ci-3;
			animation-duration: 2400ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
	}
}
.EmailIcon:hover #email-icon{
	animation-name: rotate-grow-email;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}


.UIIcon{
	@media (max-width: 768px) {
		#rect-UI-top{
			animation-name: ui-top;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#rect-UI-middle{
			animation-name: ui-middle;
			animation-duration: 1000ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#rect-UI-bottom{
			animation-name: ui-bottom;
			animation-duration: 1800ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#ci-UI-1{
			animation-name: ui-ci-1;
			animation-duration: 2000ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#ci-UI-2{
			animation-name: ui-ci-2;
			animation-duration: 2200ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#ci-UI-3{
			animation-name: ui-ci-3;
			animation-duration: 2400ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
	}
}
.UIIcon:hover #rect-UI-top{
	animation-name: ui-top;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}
.UIIcon:hover #rect-UI-middle{
	animation-name: ui-middle;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}
.UIIcon:hover #rect-UI-bottom{
	animation-name: ui-bottom;
	animation-duration: 1800ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}
.UIIcon:hover #ci-UI-1{
	animation-name: ui-ci-1;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}
.UIIcon:hover #ci-UI-2{
	animation-name: ui-ci-2;
	animation-duration: 2200ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}
.UIIcon:hover #ci-UI-3{
	animation-name: ui-ci-3;
	animation-duration: 2400ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}



.AppDevIcon{
	@media (max-width: 768px) {
		#closing-tag{
			animation-name: separate-close-tag-app;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: center;
		}
		#open-tag{
			animation-name: separate-open-tag-app;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: center;
		}
		#separator-tag{
			animation-name: shine-tag-app;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
	}
}
.AppDevIcon:hover #closing-tag{
	animation-name: separate-close-tag-app;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: center;
}
.AppDevIcon:hover #open-tag{
	animation-name: separate-open-tag-app;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: center;
}
.AppDevIcon:hover #separator-tag{
	animation-name: shine-tag-app;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}


.SMSIcon{
	@media (max-width: 768px) {
		#sms-message{
			animation-name: popup-sms;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 40px 70px;
		}
		#sms-dot{
			animation-name: sms-dots;
			animation-duration: 1500ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 40px 70px;
		}
	}
}
.SMSIcon:hover #sms-message{
	animation-name: popup-sms;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 40px 70px;
}
.SMSIcon:hover #sms-dot{
	animation-name: sms-dots;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 40px 70px;
}

.WIFIIcon{
	@media (max-width: 768px) {
		#signal-1{
			animation-name: wifi-signal-1;
			animation-duration: 1000ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#signal-3{
			animation-name: wifi-signal-3;
			animation-duration: 1300ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
		#signal-2{
			animation-name: wifi-signal-2;
			animation-duration: 1800ms;
			animation-iteration-count: 1;
			animation-timing-function: ease;
			animation-play-state: initial;
			transform-origin: 25% 25%;
		}
	}
}

.WIFIIcon:hover #signal-1{
	animation-name: wifi-signal-1;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
}
.WIFIIcon:hover #signal-3{
	animation-name: wifi-signal-3;
	animation-duration: 1300ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
	animation-delay: 750ms;
}
.WIFIIcon:hover #signal-2{
	animation-name: wifi-signal-2;
	animation-duration: 1800ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
	animation-play-state: initial;
	transform-origin: 25% 25%;
	animation-delay: 1500ms;
}


@keyframes wifi-signal-1 {
	0% {
		transform: scale(1) translateX(82.902px) translateY(117.281px);
	}
	50% {
		transform: scale(1.2) translateX(82.902px) translateY(117.281px);
	}
	100% {
		transform: scale(1) translateX(82.902px) translateY(117.281px);
	}
}
@keyframes wifi-signal-2 {
	0% {
		transform: scale(1) translateX(-1426.907px) translateY(-84.438px);
	}
	50% {
		transform: scale(1.2) translateX(-1426.907px) translateY(-84.438px);
	}
	100% {
		transform: scale(1) translateX(-1426.907px) translateY(-84.438px);
	}
}
@keyframes wifi-signal-3 {
	0% {
		transform: scale(1) translateX(-1411.734px) translateY(-63.004px);
	}
	50% {
		transform: scale(1.2) translateX(-1411.734px) translateY(-63.004px);
	}
	100% {
		transform: scale(1) translateX(-1411.734px) translateY(-63.004px);
	}
}


@keyframes shine {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes shine-fade {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(1.5);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes move-around {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(30px);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	50% {
		transform: rotate(-5deg);
	}
	100% {
		transform: rotate(0);
	}
}

@keyframes lean-back {
	0% {
		transform: rotate(0) translateX(-71.999px) translateY(-46.113px);

	}
	50% {
		transform: rotate(-5deg) translateX(-71.999px) translateY(-46.113px);
	}
	100% {
		transform: rotate(0) translateX(-71.999px) translateY(-46.113px);

	}
}
@keyframes move-around-voice {
	0% {
		transform: translateX(0) translateX(-71.999px) translateY(-46.113px);
	}
	50% {
		transform: translateX(30px) translateX(-71.999px) translateY(-46.113px);
	}
	100% {
		transform: translateX(0) translateX(-71.999px) translateY(-46.113px);
	}
}

@keyframes separate-close-tag{
	0% {
		transform: translateX(0) translateX(-125.242px) translateY(-379.697px);
	}
	50% {
		transform: translateX(10px) translateX(-125.242px) translateY(-379.697px);
	}
	100% {
		transform: translateX(0) translateX(-125.242px) translateY(-379.697px);
	}
}
@keyframes separate-open-tag{
	0% {
		transform: translateX(0) translateX(-116.841px) translateY(-379.695px);
	}
	50% {
		transform: translateX(-10px) translateX(-116.841px) translateY(-379.695px);
	}
	100% {
		transform: translateX(0) translateX(-116.841px) translateY(-379.695px);
	}
}
@keyframes shine-tag{
	0% {
		transform: scale(1) translateX(-121.779px) translateY(-378.909px);
	}
	50% {
		transform: scale(1.1) translateX(-121.779px) translateY(-378.909px);
	}
	100% {
		transform: scale(1) translateX(-121.779px) translateY(-378.909px);
	}
}
@keyframes rotate-grow-email{
	0% {
		transform: scale(1) translateX(-1228.113px) translateY(-59.106px);
	}
	50% {
		transform: scale(0.8) translateX(-1228.113px) translateY(-59.106px);
	}
	100% {
		transform: scale(1) translateX(-1228.113px) translateY(-59.106px);
	}
}

@keyframes ui-top{
	0% {
		transform:translateX(62.14px) translateY(50.03px);
	}
	50% {
		width: 30px;
		transform:translateX(62.14px) translateY(50.03px);
	}
	100% {
		transform:translateX(62.14px) translateY(50.03px);
	}
}
@keyframes ui-middle{
	0% {
		transform:translateX(45.04px) translateY(81.43px);
	}
	50% {
		width: 50px;
		transform:translateX(45.04px) translateY(81.43px);
	}
	100% {
		transform:translateX(45.04px) translateY(81.43px);
	}
}
@keyframes ui-bottom{
	0% {
		transform:translateX(51.44px) translateY(156.68px);
	}
	50% {
		width: 50px;
		height: 20px;
		transform:translateX(51.44px) translateY(156.68px);
	}
	100% {
		transform:translateX(51.44px) translateY(156.68px);
	}
}

@keyframes ui-ci-1{
	0% {
		transform:translateX(66.68px) translateY(119.92px);
	}
	50% {
		transform:translateY(5px) translateX(66.68px) translateY(119.92px);
	}
	100% {
		transform:translateX(66.68px) translateY(119.92px);
	}
}
@keyframes ui-ci-2{
	0% {
		transform:translateX(34.05px) translateY(119.92px);
	}
	50% {
		transform:translateY(5px) translateX(34.05px) translateY(119.92px);
	}
	100% {
		transform:translateX(34.05px) translateY(119.92px);
	}
}
@keyframes ui-ci-3{
	0% {
		transform:translateX(1.42px) translateY(119.92px);
	}
	50% {
		transform:translateY(5px) translateX(1.42px) translateY(119.92px);
	}
	100% {
		transform:translateX(1.42px) translateY(119.92px);
	}
}

@keyframes separate-close-tag-app{
	0% {
		transform: translateX(0) translateX(-396.389px) translateY(-382.344px);
	}
	50% {
		transform: translateX(5px) translateX(-396.389px) translateY(-382.344px);
	}
	100% {
		transform: translateX(0) translateX(-396.389px) translateY(-382.344px);
	}
}
@keyframes separate-open-tag-app{
	0% {
		transform: translateX(0) translateX(-390.738px) translateY(-382.343px);
	}
	50% {
		transform: translateX(-5px) translateX(-390.738px) translateY(-382.343px);
	}
	100% {
		transform: translateX(0) translateX(-390.738px) translateY(-382.343px);
	}
}
@keyframes shine-tag-app{
	0% {
		transform: scale(1) translateX(-394.06px) translateY(-381.814px);
	}
	50% {
		transform: scale(1.1) translateX(-394.06px) translateY(-381.814px);
	}
	100% {
		transform: scale(1) translateX(-394.06px) translateY(-381.814px);
	}
}

@keyframes popup-sms{
	0% {
		transform: scale(1) translateX(-1083.434px) translateY(-319.535px);
	}
	50% {
		transform: scale(0.6) translateX(-1083.434px) translateY(-319.535px);
	}
	100% {
		transform: scale(1) translateX(-1083.434px) translateY(-319.535px);
	}
}

@keyframes sms-dots{
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}


